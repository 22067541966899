import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BackTo from '../../common/component/BackTo';
import TextOutput from '../../common/component/TextOutput';
import CardIcon from '../../common/icons/CardIcon';
import ChevronLeftIcon from '../../common/icons/ChevronLeftIcon';
import {DefaultState} from '../../common/reducer/reducers';
import {showError, showMessage} from '../../message/action/messageActions';
import paymentProviderIntegrationApi from '../api/paymentProviderIntegrationApi';
import '../style/SepaDirectDebitMandate.scss';
import SepaDirectDebitActiveMandate from './SepaDirectDebitActiveMandate';
import SepaDirectDebitMandateForm from './SepaDirectDebitMandateForm';

loadStripe.setLoadParameters({advancedFraudSignals: false});

const initialSepaMandate =
    {
        payerName: '',
        payerEmailAddress: '',
        reference: '',
        created: new Date(),
        active: false
    };

function SepaDirectDebitMandate() {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const stripePromise = loadStripe(window.environment.stripePublicKey!);
    const account = useSelector((state: DefaultState) => state.user.account);
    const [isFormVisible, setFormVisible] = useState(false);
    const [sepaMandate, setSepaMandate] = useState(initialSepaMandate);

    const credits = ['20 €', '40 €', '60 €', '80 €'];
    const directDebitsAmounts = ['80 €', '150 €', '200 €', '200 €'];
    const headers = [t('Finance.AMOUNT_SIMPLE'), t('Finance.CREDIT_LESS_THAN'), t('Finance.DIRECT_DEBIT_AMOUNT')];

    function getAmountText(amount: number) {
        if (amount === 1) {
            return `${amount} ${t('Finance.CHILD')}`;
        } else if (amount === 4) {
            return `${amount} ${t('Finance.AND_MORE')}`;
        } else {
            return `${amount} ${t('Finance.CHILDREN')}`;
        }
    }

    const loadSepaMandateById = useCallback((setupIntentId: string) => {
        paymentProviderIntegrationApi.fetchSepaMandate(setupIntentId).then(response => {
            if (response.data.success && response.data.result) {
                setSepaMandate(response.data.result);
                setFormVisible(false);
            }
        });
    }, []);

    useEffect(() => {
        if (account) {
            paymentProviderIntegrationApi.fetchSepaMandateForAccount(account.id).then(response => {
                if (response.data.success && response.data.result) {
                    setSepaMandate(response.data.result);
                    setFormVisible(false);
                }
            });
        }
    }, [account]);

    const revokeMandate = useCallback(() => {
        paymentProviderIntegrationApi.revokeSepaMandate(account!.id).then((response) => {
            if (response.data.success) {
                dispatch(showMessage('Finance.SEPA.DELETE_SUCCESS'));
            } else {
                dispatch(showError('Error.GENERAL'));
            }
            setSepaMandate(initialSepaMandate);
        });
    }, [account, dispatch]);

    return (
        <div className="container">
            <div className="center-screen">
                {
                    isFormVisible ?
                        <div className="text-primary back-link" onClick={() => setFormVisible(false)}>
                            <span><ChevronLeftIcon/></span>
                            <span className="ml-2">{t('Finance.BACK')}</span>
                        </div>
                        :
                        <BackTo path="/finances" labelKey="Finance.TITLE"/>
                }
                <h3 className="d-flex mt-4 mb-3">
                    <span><CardIcon size={1.5}/></span>
                    <span className="mt-1 ml-3">{t('Finance.SEPA_DIRECT_DEBIT_MANDATE')}</span>
                </h3>

                <SepaDirectDebitActiveMandate
                    sepaMandate={sepaMandate}
                    onRevokeMandate={revokeMandate}
                />

                <div className="card">
                    <div className="card-body">
                        <div className="px-2">

                            {
                                isFormVisible ?
                                    <Elements stripe={stripePromise}>
                                        <SepaDirectDebitMandateForm
                                            account={account}
                                            onSaveCompleted={loadSepaMandateById}
                                            initialSepaMandate={initialSepaMandate}
                                        />
                                    </Elements>
                                    :
                                    <div>
                                        {
                                            !sepaMandate.active &&
                                            <span className="text-success">{t('Finance.RECOMMENDED')}</span>
                                        }
                                        <h4>{t('Finance.AUTOMATIC_TOP_UP')}</h4>
                                        <TextOutput
                                            label={t('Finance.DIRECT_DEBIT_INFORMATION')}
                                            text={''}/>
                                        <TextOutput
                                            label={t('Finance.DIRECT_DEBIT_DISCLAIMER')}
                                            text={''}
                                        />

                                        <div className="table-container information p-0">
                                            <table role="table" className="table d-inline-block d-sm-table overflow-auto">
                                                <thead className="thead-light" key="header">
                                                <tr role="row">{
                                                    headers.map((header) => {
                                                        return <th scope="col" className="" colSpan={1} role="columnheader" key={header + '_header'}>
                                                            {header}</th>;
                                                    })
                                                }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr role="row" className="">
                                                    <td role="cell">{
                                                        [...Array(4)].map((item, index) =>
                                                            <span key={'span_' + index}>{getAmountText(index + 1)}</span>)
                                                    }</td>
                                                    <td role="cell">{
                                                        credits.map((credit) => {
                                                            return <span key={'span_' + credit}>{credit}</span>;
                                                        })
                                                    }</td>
                                                    <td role="cell">{
                                                        directDebitsAmounts.map((debitAmount, index) => {
                                                            return <span key={'amount_' + index}>{debitAmount}</span>;
                                                        })
                                                    }</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        {
                                            !sepaMandate.active &&
                                            <div className="text-right mb-3 mt-3">
                                                <button className="btn btn-primary mr-3" onClick={() => setFormVisible(true)}>
                                                    {t('Finance.SETUP_DIRECT_DEBIT')}
                                                </button>
                                            </div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SepaDirectDebitMandate;
