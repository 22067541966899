import {RoleEnum} from '../../types/enums/RoleEnum';

declare const gtag: Function; // there is a tag in index.html that loads google's script

const GOOGLE_ANALYTICS_URL = `https://www.googletagmanager.com/gtag/js?id=${window.environment.googleAnalyticsId}`;
export const isScriptLoaded = (src: string) => {
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
        if (scripts[i].src === src) {
            return true;
        }
    }
    return false;
};

export const initGA = () => {
    if (window.environment.googleAnalyticsId) {
        const script = document.createElement('script');

        script.src = GOOGLE_ANALYTICS_URL;

        script.async = true;
        if (!isScriptLoaded(GOOGLE_ANALYTICS_URL)) {
            document.body.appendChild(script);
            gtag('config', window.environment.googleAnalyticsId);
        }

    }
};

export const trackGARole = (role: RoleEnum | null) => {
    initGA();
    if (role) {
        gtag('event', role);
    }
};

export const denyGA = () => {
    if (window.environment.googleAnalyticsId) {
        const script = document.createElement('script');

        script.src = GOOGLE_ANALYTICS_URL;

        script.async = true;
        if (isScriptLoaded(GOOGLE_ANALYTICS_URL)) {
            document.body.removeChild(script);
        }
    }
};
