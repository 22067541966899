import {showError} from '../../message/action/messageActions';
import {store} from '../../store';
import {HIDE_LOADER, SHOW_LOADER} from '../action/LoaderAction';
import ErrorCode from '../enums/ErrorCode';

export function setGlobalRequestInterceptor(httpClient) {
    httpClient.interceptors.request.use((request => {
            if (!request.immediate) {
                store.dispatch({type: SHOW_LOADER});
            }
            const apiKey = window.sessionStorage.getItem('api-key');
            if (!!apiKey && apiKey !== 'undefined') {
                request.headers.Authorization = `Bearer ${apiKey}`;
            }
            return request;
        }
    ));
}

export function setGlobalResponseInterceptor(httpClient) {
    httpClient.interceptors.response.use(response => {
        store.dispatch({type: HIDE_LOADER});
        if (response.data && !response.data.success && response.data.errorResponse) {
            const errorValue = Object.values(ErrorCode).find(value => value.code === response.data.errorResponse.errorCode);
            if (!errorValue || !errorValue.silent) {
                handleErrorMessage(response.data.errorResponse.errorCode);
            }
        }

        return response;
    }, (error) => {
        let requestError = null;
        let errorMessage = null;
        let errorData = null;
        store.dispatch({type: HIDE_LOADER});
        if (error && error.response &&
            error.response.data &&
            error.response.data.errorResponse &&
            error.response.data.errorResponse.errorCode) {
            requestError = error.response.data.errorResponse.errorCode;
            errorMessage = error.response.data.errorResponse.message;
            errorData = error.response.data.errorResponse.errorData;
            const errorValue = Object.values(ErrorCode).find(value => value.code === requestError);
            if (!errorValue || !errorValue.silent) {
                handleErrorMessage(requestError);
            }

            if (error.response.status === 401 &&
                    (error.response.data.errorResponse.errorCode === ErrorCode.INVALID_LOGIN_SESSION.code
                    || error.response.data.errorResponse.errorCode === ErrorCode.INVALID_AUTHENTICATION.code)) {
                window.sessionStorage.removeItem('api-key');
            }
        } else if (error && error.response && error.response.status === 403) {
            store.dispatch(showError('Error.NOT_AUTHORIZED'));
        } else if (error && error.response && error.response.status !== 401) {
            store.dispatch(showError('Error.GENERAL'));
        }

        return {
            data: {
                success: false
            },
            errorCode: requestError,
            errorMessage: errorMessage,
            errorData: errorData
        };
    });
}

function handleErrorMessage(errorCode) {
    const errorMessage = Object.keys(ErrorCode).find(key => ErrorCode[key].code === errorCode);
    if (errorMessage) {
        store.dispatch(showError(`Error.${errorMessage}`));
    } else {
        store.dispatch(showError('Error.GENERAL'));
    }
}
