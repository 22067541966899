import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {showMessage} from '../message/action/messageActions';
import {updateBalance} from '../user/action/userActions';

export function useOcWebSocket() {
    const WEBSOCKET_URL = window.location.protocol + '//' + window.location.host + process.env.REACT_APP_WEBSOCKET_BASEPATH;
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const currencyFormatter = useMemo(() => new Intl.NumberFormat(currentLanguage, {
        style: 'currency',
        currency: 'EUR'
    }), [currentLanguage]);
    const dispatch = useDispatch();
    const isParentLoggedIn = useSelector(state => state.user.isLoggedIn && state.user.login.role === 'PARENT');
    const {
        readyState,
        lastJsonMessage,
        sendJsonMessage
    } = useWebSocket(WEBSOCKET_URL, {}, isParentLoggedIn);

    useEffect(() => {
        if (lastJsonMessage?.type === 'STATE_UPDATE' && (lastJsonMessage.payload.updateType === 'balance' || lastJsonMessage.payload.updateType === 'balance_silent')) {
            const balanceUpdateNotification = {
                updateAmount: lastJsonMessage.payload.updateAmount,
                balance: lastJsonMessage.payload.balance
            };
            dispatch(updateBalance(balanceUpdateNotification));

            if (lastJsonMessage.payload.updateType !== 'balance_silent') {
                const labelKey = balanceUpdateNotification.updateAmount >= 0 ? 'Finance.BALANCE_UPDATE_NOTIFICATION' : 'Finance.NEGATIVE_BALANCE_UPDATE_NOTIFICATION';
                // We need a positive amount in both types of messages:
                const displayAmount = Math.abs(balanceUpdateNotification.updateAmount);
                dispatch(showMessage(labelKey,
                    {
                        updateAmount: currencyFormatter.format(displayAmount),
                        balance: currencyFormatter.format(balanceUpdateNotification.balance)
                    }));
            }
        }
    }, [lastJsonMessage, currencyFormatter, dispatch]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            const apiKey = window.sessionStorage.getItem('api-key');
            sendJsonMessage({
                type: 'AUTH',
                payload: {apiKey}
            });
        }
    }, [readyState, sendJsonMessage]);
}
