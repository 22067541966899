import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import './styles/dummy.scss';

function Dummy() {
    const [t] = useTranslation();
    const href = window.location.href;
    const indexOfSuffix = window.environment.urlSuffix ? href.indexOf(window.environment.urlSuffix) : -1;
    const website = indexOfSuffix > 0 ? href.substring(href.indexOf('://') + 3, indexOfSuffix) : '';

    return (
        <div id="dummy">
            <div id="content" className="container">
                <h1><Trans t={t} i18nKey="Caterer.WEBSITE_NOT_FOUND" values={{website: website}} components={{i: <i/>}}/></h1>
            </div>
        </div>
    );
}

export default Dummy;
