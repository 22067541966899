import JsFileDownloader from 'js-file-downloader';

interface Props<T extends object> {
    url: string,
    fileName: string,
    onSuccess?: () => void,
    onError?: () => void,
    onFinish?: () => void,
    params?: T
}

type HeaderProps = {
    name: string,
    value: string
}

interface JsFileDownloadParam {
    url: string,
    headers: Array<HeaderProps>,
    withCredentials: false,
    filename: string
}

export const fileDownload = <T extends object>(props: Props<T>) => {
    const apiKey = window.sessionStorage.getItem('api-key');
    const jsFileParam: JsFileDownloadParam = {
        url: createUrl(props.url, props.params as object),
        headers: [{
            name: 'Authorization',
            value: `Bearer ${apiKey}`
        }],
        withCredentials: false,
        filename: props.fileName
    };
    new JsFileDownloader(jsFileParam).then(function () {
        if (props.onSuccess) {
            props.onSuccess();
        }
    }).catch(function (error) {
        if (props.onError) {
            props.onError();
        }
    }).finally(function () {
        if (props.onFinish) {
            props.onFinish();
        }
    });
};

function createUrl(inputUrl: string, requestParams: {} | null): string {
    const stringUrl = `${window.location.protocol + '//' + window.location.host + process.env.REACT_APP_API_URL_BASEPATH}${inputUrl}`;
    if (requestParams == null) {
        return stringUrl;
    }
    const url = new URL(stringUrl);
    const params = url.searchParams;
    Object.entries(requestParams).forEach(([key, value], index) => {
        if (value !== null) {
            const myValue = value as string;
            if (myValue.length > 0) {
                params.append(key, myValue);
            }
        }
    });
    return url.toString();
}
